import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
  FormGroup,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { v4 as uuidv4 } from "uuid";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";


function AbonosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_ABONOS = process.env.REACT_APP_URL_ABONOS;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_NOTAS_CARGO = process.env.REACT_APP_URL_NOTAS_CARGO;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [text, setText] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedClienteNombre, setSelectedClienteNombre] = useState("");
  const [fecha, setFecha] = useState(hoy);

  const [selectedBanco, setSelectedBanco] = useState("");
  const [bancos, setBancos] = useState([]);
  const [formaPago, setFormaPago] = useState("");

  const [value, setValue] = useState("");
  const [validaBoton, setValidaBoton] = useState(true);
  const [concepto, setConcepto] = useState("");
  const [total, setTotal] = useState(0);
  const [codigoAgrupado, setCodigoAgrupado] = useState(uuidv4());

  const [inputFields, setInputFields] = useState([]);
  const [inputFieldsNC, setInputFieldsNC] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  useMemo(() => {
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_BANCOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allBancos = response.data;
        setBancos(allBancos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_SURTIDOS}Cliente/${selectedCliente}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCargos = response.data;
        let arrayTabla = allCargos
        .sort((a, b) => (a.idSurtido > b.idSurtido ? 1 : -1))
          .map((a) => {
            return {
              id: a._id,
              fecha: a.fecha,
              total_general: a.total_general,
              saldo: a.saldo,
              nota: a.idSurtido,
              importe: a.saldo,
              clientes: a.clientes[0]._id,
              colaboradores: a.colaboradores[0]._id,
              observaciones: "NA " + "Fact " + a.factura,
              movimiento: "Nota",
              select:false
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        setInputFields(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_NOTAS_CARGO}Cliente/${selectedCliente}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCargosNC = response.data;
        let arrayTablaNC = allCargosNC
        .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))
          .map((a) => {
            return {
              id: a._id,
              fecha: a.fecha,
              total_general: a.importe,
              saldo: a.saldo,
              nota: a.idNotaCargo,
              importe: a.saldo,
              clientes: a.clientes[0]._id,
              colaboradores: a.clientes[0].contacto[0]._id,
              observaciones: a.observaciones,
              movimiento: "Nota de Cargo",
              select:false
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinalNC = Object.values(arrayTablaNC);
        setInputFieldsNC(dataFinalNC);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedCliente]);

  const handleChangeInputImporte = (value, name, id) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if (value <= i.saldo || value == null) {
          i[name] = value;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo",
          });
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputImporteNC = (value, name, id) => {
    const newInputFieldsNC = inputFieldsNC.map((i) => {
      if (id === i.id) {
        if (value <= i.saldo || value == null) {
          i[name] = value;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo",
          });
        }
      }
      return i;
    });
    setInputFieldsNC(newInputFieldsNC);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let activos = inputFields.filter((e) => e.select == true);
    let TEfe = activos.map((c) => parseFloat(c.importe));
    let TE = TEfe.reduce((t, total, index) => t + total, 0);

    let activosNC = inputFieldsNC.filter((e) => e.select == true);
    let TEfeNC = activosNC.map((c) => parseFloat(c.importe));
    let TENC = TEfeNC.reduce((t, total, index) => t + total, 0);

    setTotal(TE + TENC);

  }

  function savePago() {
    if (
      value != null &&
      concepto != "" &&
      formaPago != "" &&
      selectedBanco != ""
    ) {
        Swal.fire({
          title: "Estas seguro?",
          text: "Se registrará el abono",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Enviar!",
        }).then((result) => {
          if (result.isConfirmed) {
            toggleProgreso();
            
            let totalMails = inputFields.length  + inputFieldsNC.length;
            inputFields.map((a) => {
              if (a.select == true && a.importe > 0) {
                axios
                  .post(
                    URL_ABONOS,
                    {
                      fecha,
                      importe: parseFloat(a.importe),
                      clientes: selectedCliente,
                      colaboradores: a.colaboradores,
                      surtidos: a.id,
                      formaPago,
                      concepto,
                      bancos: selectedBanco,
                      comisionPagada: "No",
                      observaciones: a.observaciones,
                      codigoAgrupado,
                      editado:"No",
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then((data) => {
                    axios.patch(
                      `${URL_SURTIDOS}/${a.id}`,
                      {
                        saldo: a.saldo - a.importe,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    );
                    totalMails = totalMails - 1;
                    if (totalMails == 0) {
                      Swal.fire("Good job!", "Creado con exito", "success");

                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      // footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
              } else {
                totalMails = totalMails - 1;
                if (totalMails == 0) {
                  Swal.fire("Good job!", "Creado con exito", "success");

                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              }
            });

            // Notas Cargo
            inputFieldsNC.map((a) => {
              if (a.select == true && a.importe > 0) {
                axios
                  .post(
                    URL_ABONOS,
                    {
                      fecha,
                      importe: a.importe,
                      clientes: selectedCliente,
                      colaboradores: a.colaboradores,
                      notasCargo: a.id,
                      formaPago,
                      concepto,
                      bancos: selectedBanco,
                      comisionPagada: "No",
                      observaciones: a.observaciones,
                      codigoAgrupado,
                      editado:"No",
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then((data) => {
                    axios.patch(
                      `${URL_NOTAS_CARGO}AplicarAbono/${a.id}`,
                      {
                        saldo: a.saldo - a.importe,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    );
                    totalMails = totalMails - 1;
                    if (totalMails == 0) {
                      Swal.fire("Good job!", "Creado con exito", "success");

                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      // footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
              } else {
                totalMails = totalMails - 1;
                if (totalMails == 0) {
                  Swal.fire("Good job!", "Creado con exito", "success");

                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              }
            });
          }
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Debes completar todo el formulario",
      });
    }
  }

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  const handleChangeInputNC = (id, event) => {
    const newInputFieldsNC = inputFieldsNC.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFieldsNC(newInputFieldsNC);
  };

  const options = clientes.map((option) => {
    const junta = option.nombre_comercial + " " + option.codigo;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function IA(e) {

    let activos = inputFields.filter((e) => e.select == true);
    let TEfe = activos.map((c) => parseFloat(c.importe));
    let TE = TEfe.reduce((t, total, index) => t + total, 0);

    let activosNC = inputFieldsNC.filter((e) => e.select == true);
    let TEfeNC = activosNC.map((c) => parseFloat(c.importe));
    let TENC = TEfeNC.reduce((t, total, index) => t + total, 0);

    setTotal(TE + TENC);

  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_admin ? (
        <div className="card container col-sm-11">
          <h3 align="center">Captura un Pago</h3>
          <Form>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha</Label>
                  <Input
                    type="date"
                    value={fecha}
                    required
                    // disabled
                    onChange={(e) => {
                      setFecha(e.target.value);
                    }}
                  />
                </Col>
                <Col md={3}>
                  <Label className="mr-sm-2">Cliente</Label>
                  <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedCliente) => {
                      if (selectedCliente) {
                        setValue(selectedCliente);
                        setSelectedCliente(selectedCliente._id);
                        setSelectedClienteNombre(
                          selectedCliente.nombre_comercial
                        );
                      }
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Concepto</Label>
                  <Input
                    type="select"
                    value={concepto}
                    onChange={(e) => {
                      setConcepto(e.target.value);
                    }}
                  >
                    <option value="">Selecciona un Concepto</option>
                    <option value="Abono">Abono</option>
                    <option value="Bonificacion">Bonificacion</option>
                  </Input>
                </Col>

                <Col md={2}>
                  <Label className="mr-sm-2">Forma de Pago</Label>
                  <Input
                    type="select"
                    value={formaPago}
                    onChange={(e) => {
                      setFormaPago(e.target.value);
                    }}
                  >
                    <option value="">Selecciona una Forma de Pago</option>
                    <option value="Efectivo">Efectivo</option>
                    <option value="Transfer">Transfer</option>
                    <option value="TDC">TDC</option>
                    <option value="Bonificacion">Bonificacion</option>
                  </Input>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Banco</Label>
                  <Input
                    type="select"
                    value={selectedBanco}
                    onChange={(e) => {
                      setSelectedBanco(e.target.value);
                    }}
                  >
                    <option value="">Selecciona un Banco</option>
                    {bancos
                      .sort((a, b) => (a.banco > b.banco ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.banco} {a.cuenta}
                          </option>
                        );
                      })}
                  </Input>
                </Col>

                <Col md={1}>
                  <h4 align="right">
                    Total Pago{"  "}
                    <Badge
                      id="Total"
                      color="danger"
                      className="BadgeSize badge-pill"
                    >
                      {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(total)}
                    </Badge>
                  </h4>
                </Col>
              </Row>
              <br />
              <Row>
                {/* Tabla Cargos */}
                <br />
                <br />
                <Table striped borderless>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Fecha</th>
                      <th>Movimiento</th>
                      <th>Nota</th>
                      <th>Observaciones</th>
                      <th>Total</th>
                      <th>Abonos</th>
                      <th>Saldo</th>
                      <th>Importe</th>
                      <th>Observaciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {inputFields.map((a) => (
                      // <div key={a.id}>
                      <tr>
                        <th>
                        <Input
                              type="checkbox"
                              onChange={e => {
                                let checked = e.target.checked;
                                setInputFields(
                                  inputFields.map(data => {
                                    if(a.id == data.id){
                                    data.select = checked;
                                    } 
                                    return data;
                                  })
                                );
                                ActualizaTotales()
                              }}
                              checked={a.select}
                            />
                        </th>
                        <td>{a.fecha}</td>
                        <td>{a.movimiento}</td>
                        <td>{a.nota}</td>
                        <td>{a.observaciones}</td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(
                              a.total_general
                            )}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(
                              a.total_general - a.saldo
                            )}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.saldo)}
                        </td>
                        <td>
                          { a.select?(
                              <CurrencyInput
                              intlConfig={{ locale: "en-US", currency: "USD" }}
                              prefix="$"
                              name="importe"
                              defaultValue={0}
                              decimalsLimit={2}
                              value={a.importe}
                              className={`form-control`}
                              onValueChange={(value, name) =>
                                handleChangeInputImporte(value, name, a.id)
                              }
                            />
                             ):(
                          <CurrencyInput
                              intlConfig={{ locale: "en-US", currency: "USD" }}
                              prefix="$"
                              name="importe"
                              defaultValue={0}
                              decimalsLimit={2}
                              value={a.importe}
                              className={`form-control`}
                              disabled
                            />
                             )}

                        </td>
                        <td>
                          <Input
                            type="text"
                            name="observaciones"
                            value={a.observaciones}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInput(a.id, e);
                            }}
                          />
                        </td>
                      </tr>
                      // </div>
                    ))}

                    {inputFieldsNC.map((a) => (
                      // <div key={a.id}>
                      <tr>
                         <th>
                        <Input
                              type="checkbox"
                              onChange={e => {
                                let checked = e.target.checked;
                                setInputFieldsNC(
                                  inputFieldsNC.map(data => {
                                    if(a.id == data.id){
                                    data.select = checked;
                                    } 
                                    return data;
                                  })
                                );
                                
                                ActualizaTotales()
                              }}
                              checked={a.select}
                            />
                        </th>
                        <td>{a.fecha}</td>
                        <td>{a.movimiento}</td>
                        <td>{a.nota}</td>
                        <td>{a.observaciones}</td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(
                              a.total_general
                            )}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(
                              a.total_general - a.saldo
                            )}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.saldo)}
                        </td>
                        <td>
                          { a.select?(
                              <CurrencyInput
                              intlConfig={{ locale: "en-US", currency: "USD" }}
                              prefix="$"
                              name="importe"
                              defaultValue={0}
                              decimalsLimit={2}
                              value={a.importe}
                              className={`form-control`}
                              onValueChange={(value, name) =>
                                handleChangeInputImporteNC(value, name, a.id)
                              }
                            />
                             ):(
                          <CurrencyInput
                              intlConfig={{ locale: "en-US", currency: "USD" }}
                              prefix="$"
                              name="importe"
                              defaultValue={0}
                              decimalsLimit={2}
                              value={a.importe}
                              className={`form-control`}
                              disabled
                            />
                             )}

                        </td>
                        <td>
                          <Input
                            type="text"
                            name="observaciones"
                            value={a.observaciones}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInputNC(a.id, e);
                            }}
                          />
                        </td>
                      </tr>
                      // </div>
                    ))}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="negrita" align="center">
                        Total
                      </td>
                      <td className="negrita">
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(total)}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                  <br />
                </Table>

                {/* Termina tabla cargos */}
              </Row>
            </FormGroup>
            <br />
            <Row>
              <Button className="btn btn-success" onClick={savePago}>
                {" "}
                Registrar
              </Button>

              <Button
                href="/ListadoAbonos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Row>
          </Form>
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
      
    </>
  );
}

export default AbonosCreate;
