import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
} from "reactstrap";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TablaFacturas from "./TablaFacturas";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function ListadoFacturas() {
  const { user } = useContext(AuthContext);
  const URL_FACTURAS = process.env.REACT_APP_URL_FACTURAS;
  const URL_ABONOS_FACTURAS = process.env.REACT_APP_URL_ABONOS_FACTURAS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_EDO_CTA_FACTURAS = process.env.REACT_APP_URL_EDO_CTA_FACTURAS;
  const [abonosFacturas, setAbonosFacturas] = useState([]);
  const [cobradores, setCobradores] = useState([]);
  const [contactos, setContactos] = useState([]);
  const [selectedCobrador, setSelectedCobrador] = useState("");
  const [selectedContacto, setSelectedContacto] = useState("");
  const [facturas, setFacturas] = useState([]);
  const [pendiente, setPendiente] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [fecha, setFecha] = useState("");
  const [concepto, setConcepto] = useState("");
  const [text, setText] = useState(false);
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");
  const [value, setValue] = useState();

  const URL_CLIENTES = `${process.env.REACT_APP_URL_CLIENTES}`;
  const [cargosCliente, setCargosCliente] = useState([]);
  const [abonosCliente, setAbonosCliente] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("0");
  const [selectedFechaFin, setSelectedFechaFin] = useState("0");
  const [selectedUltimoPagoInicio, setSelectedUltimoPagoInicio] = useState("0");
  const [selectedUltimoPagoFin, setSelectedUltimoPagoFin] = useState("0");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  useEffect(() => {
    axios
      .get(URL_FACTURAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allFacturas = response.data;
        setFacturas(allFacturas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_ABONOS_FACTURAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAbonosFacturas = response.data;
        setAbonosFacturas(allAbonosFacturas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCobradores = response.data;
        setCobradores(allCobradores);
        let allContactos = response.data;
        setContactos(allContactos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //Array Cargos
  const cargosFacturas = facturas
    .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))
    .map((n) => {
      return {
        fecha: n.fecha,
        idCliente: n.clientes[0]._id,
        nombre: n.clientes[0].nombre,
        apellido: n.clientes[0].apellido,
        telefono: n.clientes[0].telefono,
        email: n.clientes[0].email,
        razon_social: n.clientes[0].razon_social,
        cargos: n.importe,
        abonos: 0,
        saldo: n.importe,
        cobrador:
          n.clientes[0].colaboradores[0].nombre +
          " " +
          n.clientes[0].colaboradores[0].apellido,
        idCobrador: n.clientes[0].colaboradores[0]._id,
        contacto:
          n.clientes[0].contacto[0].nombre +
          " " +
          n.clientes[0].contacto[0].apellido,
        idContacto: n.clientes[0].contacto[0]._id,
        // sala: n.salasDon[0].name,
        // institucion: n.institucionesDon[0].name,
        // evento: n.eventosDon[0].name,
        // concepto: n.conceptosDon[0].name,
        movimiento: "Cargo",
        fecha_visita: n.clientes[0].fecha_visita,
        ultimo_pago: n.clientes[0].ultimo_pago,
        is_active: n.clientes[0].is_active,
      };
    });

  //Array Abonos
  const abonosDon = abonosFacturas
    .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))
    .map((n) => {
      return {
        fecha: n.fecha,
        idCliente: n.clientes[0]._id,
        nombre: n.clientes[0].nombre,
        apellido: n.clientes[0].apellido,
        telefono: n.clientes[0].telefono,
        email: n.clientes[0].email,
        razon_social: n.clientes[0].razon_social,
        cargos: 0,
        abonos: n.importe,
        saldo: -n.importe,
        cobrador:
          n.clientes[0].colaboradores[0].nombre +
          " " +
          n.clientes[0].colaboradores[0].apellido,
        idCobrador: n.clientes[0].colaboradores[0]._id,
        contacto:
          n.clientes[0].contacto[0].nombre +
          " " +
          n.clientes[0].contacto[0].apellido,
        idContacto: n.clientes[0].contacto[0]._id,
        forma_pago: n.forma_pago,
        movimiento: "Abono",
        fecha_visita: n.clientes[0].fecha_visita,
        ultimo_pago: n.clientes[0].ultimo_pago,
        is_active: n.clientes[0].is_active,
      };
    });

  //Juntamos Arrays
  const arrayFacturas = cargosFacturas.concat(abonosDon);

  //   Agrupar abonos y cargos por cliente
  const groupByCliente = function (miarray, prop) {
    return miarray.reduce(function (groups, item) {
      const val = item[prop];
      groups[val] = groups[val] || {
        idCliente: item.idCliente,
        cargos: 0,
        abonos: 0,
        saldo: 0,
      };
      groups[val].cargos += item.cargos;
      groups[val].abonos += item.abonos;
      groups[val].saldo += item.saldo;
      groups[val].idCliente = item.idCliente;
      groups[val].nombre = item.nombre;
      groups[val].apellido = item.apellido;
      groups[val].telefono = item.telefono;
      groups[val].email = item.email;
      groups[val].razon_social = item.razon_social;
      groups[val].cobrador = item.cobrador;
      groups[val].idCobrador = item.idCobrador;
      groups[val].contacto = item.contacto;
      groups[val].idContacto = item.idContacto;
      groups[val].fecha_visita = item.fecha_visita;
      groups[val].ultimo_pago = item.ultimo_pago;
      return groups;
    }, []);
  };

  const facturasAgrupado = groupByCliente(arrayFacturas, "idCliente");
  const dataFinalFacturas = Object.values(facturasAgrupado).sort((a, b) =>
    a.nombre > b.nombre ? 1 : -1
  );

  // PDF
  let totalCargos = 0;
  let totalAbonos = 0;
  let totalSaldo = 0;

  let tablaCargos = 0;
  let tablaAbonos = 0;
  let tablaSaldo = 0;

  function PDFTabla() {
    const data = dataFinalFacturas.map((a) => {
      if (
        (selectedCobrador == 0 || selectedCobrador == a.idCobrador) &&
        (pendiente == 0 || (pendiente < a.saldo && a.saldo != 0)) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha_visita) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha_visita) &&
        (selectedUltimoPagoInicio == 0 ||
          selectedUltimoPagoInicio <= a.ultimo_pago) &&
        (selectedUltimoPagoFin == 0 ||
          selectedUltimoPagoFin >= a.ultimo_pago) &&
        (value == null || value._id == a.idCliente)
      ) {
        const cargo = "$" + new Intl.NumberFormat("en-US").format(a.cargos);
        const abono = "-$" + new Intl.NumberFormat("en-US").format(a.abonos);
        const saldos = "$" + new Intl.NumberFormat("en-US").format(a.saldo);
        totalCargos = totalCargos + a.cargos;
        totalAbonos = totalAbonos + a.abonos;
        totalSaldo = totalSaldo + a.saldo;
        return [
          a.nombre + " " + a.apellido,
          a.telefono,
          a.email,
          a.ultimo_pago,
          a.fecha_visita,
          a.cobrador,
          cargo,
          abono,
          saldos,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Notas`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Nombre",
          "Telefono",
          "Email",
          "Ult Pago",
          "Prox Visita",
          "Cobrador",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "Totales",
          "$" + new Intl.NumberFormat("en-US").format(totalCargos),
          "-$" + new Intl.NumberFormat("en-US").format(totalAbonos),
          "$" + new Intl.NumberFormat("en-US").format(totalSaldo),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`Notas.pdf`);
  }
  function excel() {
    const dataExcel = dataFinalFacturas.map((a) => {
      if (
        (selectedCobrador == 0 || selectedCobrador == a.idCobrador) &&
        (pendiente == 0 || (pendiente < a.saldo && a.saldo != 0)) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha_visita) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha_visita) &&
        (selectedUltimoPagoInicio == 0 ||
          selectedUltimoPagoInicio <= a.ultimo_pago) &&
        (selectedUltimoPagoFin == 0 ||
          selectedUltimoPagoFin >= a.ultimo_pago) &&
        (value == null || value._id == a.idCliente)
      ) {
        return {
          Nombre: a.nombre + " " + a.apellido,
          Telefono: a.telefono,
          Email: a.email,
          UltimoPago: a.ultimo_pago,
          ProxVisita: a.fecha_visita,
          Cobrador: a.cobrador,
          Cargos: a.cargos,
          Abonos: -a.abonos,
          Saldo: a.saldo,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoNotas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoNotas",
        sheetFilter: [
          "Nombre",
          "Telefono",
          "Email",
          "UltimoPago",
          "ProxVisita",
          "Cobrador",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
        sheetHeader: [
          "Nombre",
          "Telefono",
          "Email",
          "UltimoPago",
          "ProxVisita",
          "Cobrador",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = dataFinalFacturas.map((a) => {
      if (
        (selectedCobrador == 0 || selectedCobrador == a.idCobrador) &&
        (pendiente == 0 || (pendiente < a.saldo && a.saldo != 0)) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha_visita) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha_visita) &&
        (selectedUltimoPagoInicio == 0 ||
          selectedUltimoPagoInicio <= a.ultimo_pago) &&
        (selectedUltimoPagoFin == 0 ||
          selectedUltimoPagoFin >= a.ultimo_pago) &&
        (value == null || value._id == a.idCliente)
      ) {
        const cargo = "$" + new Intl.NumberFormat("en-US").format(a.cargos);
        const abono = "-$" + new Intl.NumberFormat("en-US").format(a.abonos);
        const saldos = "$" + new Intl.NumberFormat("en-US").format(a.saldo);
        totalCargos = totalCargos + a.cargos;
        totalAbonos = totalAbonos + a.abonos;
        totalSaldo = totalSaldo + a.saldo;
        return [
          a.nombre + " " + a.apellido,
          a.telefono,
          a.email,
          a.ultimo_pago,
          a.fecha_visita,
          a.cobrador,
          cargo,
          abono,
          saldos,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Notas`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Nombre",
          "Telefono",
          "Email",
          "Ult Pago",
          "Prox Visita",
          "Cobrador",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "Totales",
          "$" + new Intl.NumberFormat("en-US").format(totalCargos),
          "-$" + new Intl.NumberFormat("en-US").format(totalAbonos),
          "$" + new Intl.NumberFormat("en-US").format(totalSaldo),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Resumen Notas",
          email: mailTo,
          fileName: "Resumen-Notas.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el resumen de notas.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function enviarEdoCtaMasivo() {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se enviara un mail a cada cliente con su estado de cuenta!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Enviar!",
    }).then((result) => {
      if (result.isConfirmed) {
        toggleProgreso();
        let totalMails = clientes.length;

        clientes.map((a) => {
          axios
            .post(
              URL_EDO_CTA_FACTURAS,
              {
                clientes: a._id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then(() => {
              totalMails = totalMails - 1;
              if (totalMails == 0) {
                Swal.fire("Good job!", "Creado con exito", "success");
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        });
      }
    });
  }

  const options = clientes.map((option) => {
    const junta =
      option.apellido + " " + option.nombre + " / " + option.nombre_comercial;
    const firstLetter = option.apellido[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_facturas ? (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <Row>
                  <Col sm={8}>
                    <Button
                      size="sm"
                      href="/MenuClientes"
                      className="btn btn-danger"
                      id="botonListado"
                    >
                      Regresar
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={enviarEdoCtaMasivo}
                    >
                      <i class="fas fa-paper-plane"></i> Edo Cta Masivo
                    </Button>
                  </Col>
                  <Col sm={4}>
                    <ButtonGroup id="logoutBoton">
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        onClick={PDFTabla}
                      >
                        PDF <i class="far fa-file-pdf"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="info"
                        onClick={toggleMail}
                      >
                        eMail <i class="fas fa-at"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="primary"
                        onClick={excel}
                      >
                        Excel <i class="far fa-file-excel"></i>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>

                <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                  <ModalHeader toggle={toggleMail}>
                    <h4>Enviar Resumen Facturas</h4>
                  </ModalHeader>
                  <ModalBody>
                    <Label className="mr-sm-2">Email</Label>
                    <Input
                      className="col-sm-12"
                      type="text"
                      value={mailTo}
                      required
                      onChange={(e) => {
                        setMailTo(e.target.value);
                      }}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button size="sm" color="success" onClick={enviaMail}>
                      Enviar
                    </Button>
                  </ModalFooter>
                </Modal>

                <h3 align="center">Estado de Cuenta</h3>

                <Table size="sm" striped borderless className="table-responsive-xl">
                  <thead>
                    <tr>
                      <th style={{ paddingBottom: "0px" }}>Cliente</th>
                      <th style={{ paddingBottom: "0px", textAlign: "Center" }}>
                        Ultimo Pago
                      </th>
                      <th style={{ paddingBottom: "0px", textAlign: "Center" }}>
                        Proxima Visita
                      </th>
                      <th style={{ paddingBottom: "0px" }}>Contacto</th>
                      <th style={{ paddingBottom: "0px" }}>Cobrador</th>
                      <th style={{ paddingBottom: "0px" }}>Cargos</th>
                      <th style={{ paddingBottom: "0px" }}>Abonos</th>
                      <th style={{ paddingBottom: "0px" }}>Saldo</th>
                      <th style={{ paddingBottom: "0px" }}>Detalle</th>
                    </tr>
                    <tr>
                      <td style={{ paddingTop: "0px" }}>
                        <Autocomplete
                          size="small"
                          value={value}
                          onChange={(event, selectedCliente) => {
                            setValue(selectedCliente);
                          }}
                          options={options.sort(
                            (a, b) =>
                              -b.firstLetter.localeCompare(a.firstLetter)
                          )}
                          groupBy={(option) => option.firstLetter}
                          getOptionLabel={(option) => option.junta}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Selecciona"
                              variant="outlined"
                            />
                          )}
                          renderOption={(option) => (
                            <React.Fragment>
                              {option.apellido} {option.nombre} /{" "}
                              {option.nombre_comercial}
                            </React.Fragment>
                          )}
                        />
                      </td>

                      <td style={{ paddingTop: "0px" }}>
                        <Col md={12}>
                          <Input
                            bsSize="sm"
                            type="date"
                            value={selectedUltimoPagoInicio}
                            required
                            onChange={(e) => {
                              setSelectedUltimoPagoInicio(e.target.value);
                            }}
                          />
                          <Input
                            bsSize="sm"
                            type="date"
                            value={selectedUltimoPagoFin}
                            required
                            onChange={(e) => {
                              setSelectedUltimoPagoFin(e.target.value);
                            }}
                          />
                        </Col>
                      </td>
                      <td style={{ paddingTop: "0px" }}>
                        <Input
                          bsSize="sm"
                          type="date"
                          value={selectedFechaInicio}
                          required
                          onChange={(e) => {
                            setSelectedFechaInicio(e.target.value);
                          }}
                        />
                        <Input
                          bsSize="sm"
                          type="date"
                          value={selectedFechaFin}
                          required
                          onChange={(e) => {
                            setSelectedFechaFin(e.target.value);
                          }}
                        />
                      </td>
                      <td style={{ paddingTop: "0px" }}>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={selectedContacto}
                          required
                          onChange={(e) => setSelectedContacto(e.target.value)}
                        >
                          <option value="0">Selecciona</option>
                          {contactos
                            .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                            .map((a) => {
                              // if (a.cobrador == "Si") {
                              return (
                                <option value={a._id}>
                                  {a.nombre} {a.apellido}
                                </option>
                              );
                              // }
                            })}
                        </Input>
                      </td>
                      <td style={{ paddingTop: "0px" }}>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={selectedCobrador}
                          required
                          onChange={(e) => setSelectedCobrador(e.target.value)}
                        >
                          <option value="0">Selecciona</option>
                          {cobradores
                            .sort((a, b) => a.nombre - b.nombre)
                            .map((a) => {
                              if (a.cobrador == "Si") {
                                return (
                                  <option value={a._id}>
                                    {a.nombre} {a.apellido}
                                  </option>
                                );
                              }
                            })}
                        </Input>
                      </td>
                      <td></td>
                      <td></td>
                      <td>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={pendiente}
                          required
                          onChange={(e) => setPendiente(e.target.value)}
                        >
                          <option value="0">Selecciona</option>
                          <option value=".000000001">Solo Pendientes</option>
                        </Input>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {dataFinalFacturas.map((c) => {
                      if (
                        (selectedCobrador == 0 ||
                          selectedCobrador == c.idCobrador) &&
                        (selectedContacto == 0 ||
                          selectedContacto == c.idContacto) &&
                        (pendiente == 0 ||
                          (pendiente < c.saldo && c.saldo != 0)) &&
                        (selectedFechaInicio == 0 ||
                          selectedFechaInicio <= c.fecha_visita) &&
                        (selectedFechaFin == 0 ||
                          selectedFechaFin >= c.fecha_visita) &&
                        (selectedUltimoPagoInicio == 0 ||
                          selectedUltimoPagoInicio <= c.ultimo_pago) &&
                        (selectedUltimoPagoFin == 0 ||
                          selectedUltimoPagoFin >= c.ultimo_pago) &&
                        (value == null || value._id == c.idCliente)
                      ) {
                        tablaCargos = tablaCargos + c.cargos;
                        tablaAbonos = tablaAbonos + c.abonos;
                        tablaSaldo = tablaSaldo + c.saldo;
                        return (
                          <tr>
                            <TablaFacturas
                              nombre={c.nombre}
                              apellido={c.apellido}
                              email={c.email}
                              razon_social={c.razon_social}
                              cargos={c.cargos}
                              abonos={c.abonos}
                              saldo={c.saldo}
                              idCliente={c.idCliente}
                              cobrador={c.cobrador}
                              contacto={c.contacto}
                              fecha_visita={c.fecha_visita}
                              ultimo_pago={c.ultimo_pago}
                            />
                          </tr>
                        );
                      }
                    })}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="negrita" align="center">
                        TOTALES
                      </td>
                      <td className="negrita" align="center">
                        {"$" +
                          new Intl.NumberFormat("en-US").format(tablaCargos)}
                      </td>
                      <td className="negrita" align="center">
                        {"-$" +
                          new Intl.NumberFormat("en-US").format(tablaAbonos)}
                      </td>
                      <td className="negrita" align="center">
                        {"$" +
                          new Intl.NumberFormat("en-US").format(tablaSaldo)}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
               <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
              </div>
            </div>
          </div>
        </div>
      ) : undefined}
    </>
  );
}

export default ListadoFacturas;
