import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesArticulos() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_articulos ?(
      <div className="container">
        <br />
        <br />
        <Row >
        <Col md={4} align="center">
          {user.menu_colores ?(
            <Button href="/Colores" className="botonesMenu" color="success">
              <i class="fas fa-palette fa-7x"></i>
              <br />
              <br />
              Colores
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-palette fa-7x"></i>
          <br />
          <br />
          Colores
        </Button> }
          </Col>
         <Col md={4} align="center">
          {user.menu_tipos_telas ?(
            <Button href="/TiposTelas" className="botonesMenu" color="success">
              <i class="fab fa-staylinked fa-7x"></i>
              <br />
              <br />
              Tipo de Tela
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fab fa-staylinked fa-7x"></i>
          <br />
          <br />
          Tipo de Tela
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.menu_tipos_telas ?(
            <Button href="/Bases" className="botonesMenu" color="success">
              <i class="fas fa-layer-group fa-7x"></i>
              <br />
              <br />
              Bases
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-layer-group fa-7x"></i>
          <br />
          <br />
          Bases
        </Button> }
          </Col>
        </Row>
        <br/>
        <Row>
          <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/ListadoArticulos" className="botonesMenu" color="success">
              <i class="fas fa-tape fa-7x"></i>
              <br />
              <br />
              Articulos
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-tape fa-7x"></i>
            <br />
            <br />
            Articulos
          </Button> }
          </Col>
          </Row>
          <br />
         
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesArticulos;
