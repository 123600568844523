import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import * as XLSX from "xlsx";
import { v4 as uuidv4 } from "uuid";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function ComprasContenedorCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_ORDENES_COMPRA = process.env.REACT_APP_URL_ORDENES_COMPRA;
  const URL_IMPORTADORAS = process.env.REACT_APP_URL_IMPORTADORAS;
  const URL_COMPRAS = process.env.REACT_APP_URL_COMPRAS;
  const URL_ARTICULOS_OC = process.env.REACT_APP_URL_ARTICULOS_OC;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;

  const URL_COMPRAS_ACUMULADOS = process.env.REACT_APP_URL_COMPRAS_ACUMULADOS;

  const [ordenesCompra, setOrdenesCompra] = useState([]);
  const [selectedOrdenCompra, setSelectedOrdenCompra] = useState([]);

  const [composicion, setComposicion] = useState("");
  const [pedimento, setPedimento] = useState("");
  const [importadoras, setImportadoras] = useState([]);
  const [selectedImportadora, setSelectedImportadora] = useState([]); 

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [colores, setColores] = useState([]);
  const [proveedores, setProveedores] = useState([]);

  const [fecha, setFecha] = useState("");
  const [factura_proveedor, setFacturaProveedor] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [pais_origen, setPaisOrigen] = useState("");
  const [total_kilos, setTotalKilos] = useState(0);
  const [total_metros, setTotalMetros] = useState(0);
  const [total_dinero, setTotalDinero] = useState(0);
  const [total_rollos, setTotalRollos] = useState(0);

  const [articulosOC, setArticulosOC] = useState([]);
  const [articulosOC2, setArticulosOC2] = useState([]);
  const [fecha_orden_compra, setFechaOrdenCompra] = useState("");
  const [plazo, setPlazo] = useState("");
  const [proveedorOrden, setProveedorOrden] = useState("");
  const [fecha_compromiso, setFechaCompromiso] = useState("");
  const [total_kilos_orden, setTotalKilosOrden] = useState(0);
  const [total_metros_orden, setTotalMetrosOrden] = useState(0);

  const [totalCaptura, setTotalCaptura] = useState(1);
  const [cantidadAgrupado, setCantidadAgrupado] = useState([]);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulosOrden: "",
      articulos: "",
      unidad: "",
      colores: "",
      cantidad: 0,
      tono: "0",
      captura: 1,
      costo: 0,
      total: 0,
      articulosName: "",
      coloresName: "",
      rollos: parseFloat(1),
    },
  ]);

  const [inputFields2, setInputFields2] = useState([]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_ORDENES_COMPRA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allOrdenesCompra = res.data;
        setOrdenesCompra(allOrdenesCompra);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColores = res.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_IMPORTADORAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allImportadoras = res.data;
        setImportadoras(allImportadoras);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveCompraNueva = async (event) => {
    event.preventDefault();
    if (
      fecha != "" &&
      observaciones != "" &&
      factura_proveedor != "" &&
      pais_origen != ""
    ) {
      setValidaBoton(false);
      toggleProgreso();

      let totalRollos =
        inputFields2.length + cantidadAgrupado.length + cantidadAgrupado.length;
      try {
        await axios
          .post(
            URL_COMPRAS,
            {
              fecha,
              ordenesCompra: selectedOrdenCompra,
              factura_proveedor,
              observaciones,
              total_kilos,
              total_metros,
              user: user.id,
              pais_origen,
              total_costo: total_dinero.toFixed(2),
              saldo: total_dinero.toFixed(2),
              proveedores: proveedorOrden,
              importadoras: selectedImportadora,
              composicion,
              pedimento,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {
            inputFields2.map((a) => {
              if (a.articulos != "" && a.cantidad != 0) {
                axios
                  .post(
                    URL_INVENTARIOS,
                    {
                      compras: data.data._id,
                      articulos: a.articulos,
                      articulosOC: a.articulosOrden,
                      colores: a.colores,
                      cantidad: parseFloat(a.cantidad),
                      tono: a.tono,
                      captura: a.captura,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalRollos = totalRollos - 1;
                    if (totalRollos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Compra",
                            detalle: `OC ${selectedOrdenCompra}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          setTimeout(() => {
                            Swal.fire("Good job!", "Creado con exito", "success");
                          }, 3000);
                          setTimeout(() => {
                            window.location.reload();
                          }, 2500);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  });
              } else {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Compra",
                        detalle: `OC ${selectedOrdenCompra}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      setTimeout(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                      }, 3000);
                      setTimeout(() => {
                        window.location.reload();
                      }, 2500);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              }
            });
            cantidadAgrupado.map((w) => {
              axios
                .patch(
                  `${URL_ARTICULOS_OC}editRecibido/${w.articulosOrden}`,
                  {
                    cantidad: w.cantidad,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalRollos = totalRollos - 1;
                  if (totalRollos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Compra",
                          detalle: `OC ${selectedOrdenCompra}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        setTimeout(() => {
                          Swal.fire("Good job!", "Creado con exito", "success");
                        }, 3000);
                        setTimeout(() => {
                          window.location.reload();
                        }, 2500);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
            });

            // Pegamos a Compras Acumulado

            cantidadAgrupado.map((w) => {
              axios
                .post(
                  `${URL_COMPRAS_ACUMULADOS}`,
                  {
                    compras: data.data._id,
                    articulos: w.articulos,
                    articulosOC: w.articulosOrden,
                    colores: w.colores,
                    cantidad: w.cantidad,
                    ordenesCompra: selectedOrdenCompra,
                    fecha,
                    proveedores: proveedorOrden,
                    total: w.total,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalRollos = totalRollos - 1;
                  if (totalRollos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Compra",
                          detalle: `OC ${selectedOrdenCompra}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        setTimeout(() => {
                          Swal.fire("Good job!", "Creado con exito", "success");
                        }, 3000);
                        setTimeout(() => {
                          window.location.reload();
                        }, 2500);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
            });

            // Terminamos de post a compras acumulado
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
    }
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulosOrden: "",
        articulos: "",
        unidad: "",
        colores: "",
        cantidad: 0,
        tono: "0",
        captura: 0,
        costo: 0,
        total: 0,
        articulosName: "",
        coloresName: "",
        rollos: parseFloat(1),
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let mts = values.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = values.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);

    let totalDin = values.map((c) => parseFloat(c.total));
    let Din = totalDin.reduce((t, total, index) => t + total, 0);
    setTotalDinero(Din);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, event) {
    articulosOC.map((a) => {
      if (a._id == event.target.value) {
        let articulosOrden = a._id;
        let idArticulo = a.articulos[0]._id;
        let unidadArticulo = a.articulos[0].unidad;
        let color = a.colores[0]._id;
        let costo = a.precio;
        let articulosName = a.articulos[0].nombre;
        let coloresName = a.colores[0].name;
        handleChangeInputArticulo(
          id,
          articulosOrden,
          idArticulo,
          unidadArticulo,
          color,
          costo,
          articulosName,
          coloresName
        );
      }
    });
  }

  const handleChangeInputArticulo = (
    id,
    articulosOrden,
    idArticulo,
    unidadArticulo,
    color,
    costo,
    articulosName,
    coloresName
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulosOrden = articulosOrden;
        i.articulos = idArticulo;
        i.unidad = unidadArticulo;
        i.colores = color;
        i.costo = costo;
        i.articulosName = articulosName;
        i.coloresName = coloresName;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let mts = inputFields2.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = inputFields2.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);

    let totalDin = inputFields2.map((c) => parseFloat(c.total));
    let Din = totalDin.reduce((t, total, index) => t + total, 0);
    setTotalDinero(Din);

    let totalRollos = inputFields2.map((c) => parseFloat(c.rollos));
    let Rollos = totalRollos.reduce((t, total, index) => t + total, 0);
    setTotalRollos(Rollos);

    let agrupado = inputFields2.reduce(function (groups, item) {
      const val = item["articulos"] + item["colores"];
      groups[val] = groups[val] || {
        articulos: item.articulos,
        colores: item.colores,
        cantidad: 0,
        total: 0,
        rollos: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].rollos += item.rollos;
      groups[val].total += item.total;
      groups[val].id = item.id;
      groups[val].articulos = item.articulos;
      groups[val].articulosName = item.articulosName;
      groups[val].coloresName = item.coloresName;
      groups[val].unidad = item.unidad;
      groups[val].colores = item.colores;
      groups[val].tono = item.tono;
      groups[val].articulosOrden = item.articulosOrden;
      return groups;
    }, []);

    let CA = Object.values(agrupado);
    setCantidadAgrupado(CA);
  }

  function jalaOrden(e) {
    setSelectedOrdenCompra(e);

    axios
      .get(`${URL_ARTICULOS_OC}/ordenesCompra/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosOC = res.data;
        setArticulosOC(allArticulosOC);
      })
      .catch((err) => {
        console.log(err);
      });

    ordenesCompra.map((a) => {
      if (a._id == e) {
        setFechaOrdenCompra(a.fecha);
        setPlazo(a.plazo);
        setFechaCompromiso(a.fecha_compromiso);
        setTotalKilosOrden(a.total_kilos);
        setTotalMetrosOrden(a.total_metros);
        setProveedorOrden(a.proveedores[0]._id);
      }
    });
  }

  function importarArchivo(inputField, event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let hojas = [];

    if (name === "file") {
      let reader = new FileReader();
      reader.readAsArrayBuffer(target.files[0]);
      reader.onloadend = (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array" });

        workbook.SheetNames.forEach(function (sheetName) {
          // Here is your object
          var XL_row_object = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheetName]
          );
          hojas.push({
            data: XL_row_object,
            sheetName,
          });
        });

        let A1 = hojas[0].data;

        let TC = totalCaptura;
        A1.map((a) => {
          TC = TC + 1;
          setTotalCaptura(TC);
          inputFields2.push({
            id: uuidv4(),
            articulosOrden: inputField.articulosOrden,
            articulos: inputField.articulos,
            unidad: inputField.unidad,
            colores: inputField.colores,
            cantidad: a.cantidad,
            tono: inputField.tono,
            captura: TC,
            costo: inputField.costo,
            total: parseFloat(a.cantidad) * parseFloat(inputField.costo),
            articulosName: inputField.articulosName,
            coloresName: inputField.coloresName,
            rollos: parseFloat(1),
          });
        });

        ActualizaTotales();
      };
    }
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.compras_create ? (
        <div className="card container col-10">
          <h3 align="center">Nueva Compra</h3>
          <Form onSubmit={saveCompraNueva}>
            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Orden de Compra</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedOrdenCompra}
                  required
                  onChange={(e) => jalaOrden(e.target.value)}
                >
                  <option value="0">Selecciona una Orden</option>
                  {ordenesCompra
                    .sort((a, b) =>
                      a.idOrdenCompra < b.idOrdenCompra ? 1 : -1
                    )
                    .map((a) => {
                      if (a.status == "Abierto" && a.is_active == "Si") {
                        return (
                          <option value={a._id}>
                            {a.idOrdenCompra}{" "}
                            {a.proveedores[0].nombre_comercial} / Cont.{" "}
                            {a.contenedor}
                          </option>
                        );
                      }
                    })}
                </Input>
              </Col>
              <Col md={2}>
                <Label>Fecha OC</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha_orden_compra}
                  required
                  disabled
                  onChange={(e) => {
                    setFechaOrdenCompra(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>Plazo</Label>
                <Input
                  type="text"
                  placeholder="Plazo"
                  value={plazo}
                  required
                  disabled
                  onChange={(e) => {
                    setPlazo(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>Pedimento</Label>
                <Input
                  type="text"
                  placeholder="Pedimento"
                  value={pedimento}
                  required
                  onChange={(e) => {
                    setPedimento(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Importadora</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedImportadora}
                  required
                  onChange={(e) => setSelectedImportadora(e.target.value)}
                >
                  <option value="0">Selecciona</option>
                  {importadoras
                    .sort((a, b) => (a.nombre < b.nombre ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.nombre}</option>}
                    )}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>Factura Proveedor</Label>
                <Input
                  type="text"
                  placeholder="Factura Proveedor"
                  value={factura_proveedor}
                  required
                  onChange={(e) => {
                    setFacturaProveedor(e.target.value);
                  }}
                />
              </Col>

              <Col md={2}>
                <Label>Pais Origen</Label>
                <Input
                  type="text"
                  placeholder="Pais Origen"
                  value={pais_origen}
                  required
                  onChange={(e) => {
                    setPaisOrigen(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Composicion</Label>
                <Input
                  type="text"
                  placeholder="Composicion"
                  value={composicion}
                  required
                  onChange={(e) => {
                    setComposicion(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Articulos</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Color</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Rollos</Label>
              </Col>
            </Row>
            {cantidadAgrupado.map((ag) => {
              return (
                <>
                  <Row>
                    <Col md={2}>
                      <Label className="mr-sm-2">{ag.articulosName}</Label>
                    </Col>
                    <Col md={2}>
                      <Label className="mr-sm-2">{ag.coloresName}</Label>
                    </Col>
                    <Col md={2}>
                      <Label className="mr-sm-2">{ag.cantidad}</Label>
                    </Col>
                    <Col md={2}>
                      <Label className="mr-sm-2">{ag.rollos}</Label>
                    </Col>
                  </Row>
                </>
              );
            })}

            <Row>
              <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES {new Intl.NumberFormat("en-US").format(total_kilos)}{" "}
                  kg. / {new Intl.NumberFormat("en-US").format(total_metros)}{" "}
                  mts. / {new Intl.NumberFormat("en-US").format(total_rollos)}{" "}
                  rollos
                </h4>
              </Col>
            </Row>

            {/* Tabla Articulos */}

            <Row>
              <Col md={4}>
                <Label className="mr-sm-2">Articulos</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Unidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Tono</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={4}>
                    <Input
                      bsSize="sm"
                      name="articulos"
                      type="select"
                      value={inputField.articulosOrden}
                      required
                      onChange={(event) => {
                        BuscaArticulo(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona un Articulo</option>
                      {articulosOC
                        .sort(function (x, y) {
                          return (
                            y.articulos[0].codigo - x.articulos[0].codigo ||
                            x.colores[0]._id - y.colores[0]._id
                          );
                        })
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.articulos[0].codigo} / {a.articulos[0].nombre}{" "}
                              / {a.colores[0].name}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="unidad"
                      type="string"
                      value={inputField.unidad}
                      disabled
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="tono"
                      type="text"
                      placeholder="Tono"
                      value={inputField.tono}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col>
                    <Input
                      required
                      type="file"
                      name="file"
                      id="file"
                      onChange={(event) => importarArchivo(inputField, event)}
                    />
                  </Col>

                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <Row>
              <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES {new Intl.NumberFormat("en-US").format(total_kilos)}{" "}
                  kg. / {new Intl.NumberFormat("en-US").format(total_metros)}{" "}
                  mts. / {new Intl.NumberFormat("en-US").format(total_rollos)}{" "}
                  rollos
                </h4>
              </Col>
            </Row>
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoCompras"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios minutos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default ComprasContenedorCreate;
