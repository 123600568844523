import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import BajaMultiple from "../../BajaMultiple";
import StatusMultiple from "../../StatusMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import moment from 'moment';
import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";

function ListadoAbonosAgrupado() {
  const { user } = useContext(AuthContext);
  const URL_ABONOS = process.env.REACT_APP_URL_ABONOS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_USERS = process.env.REACT_APP_URL_USERS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;

  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-30);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [abonos, setAbonos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);
  const [validado, setValidado] = useState("");

  const [selectedBanco, setSelectedBanco] = useState("");
  const [bancos, setBancos] = useState([]);
  const [formaPago, setFormaPago] = useState("");
  const [concepto, setConcepto] = useState("");
  const [activo, setActivo] = useState("");


  const [idAbonoEdit, setIdAbonoEdit] = useState("");
  const [numeroEdit, setNumeroEdit] = useState("");
  const [fechaEdit, setFechaEdit] = useState("");
  const [idClienteEdit, setIdClienteEdit] = useState("");
  const [importeEdit, setImporteEdit] = useState("");
  const [importeEditViejo, setImporteEditViejo] = useState("");
  const [formaPagoEdit, setFormaPagoEdit] = useState("");
  const [clienteEdit, setClienteEdit] = useState("");
  const [notaEdit, setNotaEdit] = useState("");
  const [observacionesEdit, setObservacionesEdit] = useState("");
  const [conceptoEdit, setConceptoEdit] = useState("");
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [modalEdit2, setModalEdit2] = useState(false);
  const toggleEdit2 = () => setModalEdit2(!modalEdit2);
  const [validaBoton, setValidaBoton] = useState(true);
  const [text, setText] = useState(false);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);


  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(()=>{
    axios
      .get(`${URL_ABONOS}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allVentas = response.data;
        // Array para pagination
        let arrayTabla = allVentas
          .sort((a, b) => (a.idAbono < b.idAbono ? 1 : -1))
          .map((a) => {
            if(a.surtidos!=""){
            return {
              _id: a._id,
              numero: a.idAbono,
              fecha: a.fecha,
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              bancos: a.bancos[0].banco + " " + a.bancos[0].cuenta,
              idBancos: a.bancos[0]._id,
              formaPago: a.formaPago,
              observaciones: a.observaciones,
              concepto: a.concepto,
              importe: a.importe,
              nota: "Nota" + " " + a.surtidos[0].idSurtido,
              idNota: a.surtidos[0]._id,
              editado:a.editado,
              is_active: a.is_active,
              codigoAgrupado: a.codigoAgrupado,
              validado:a.validado,
              colaboradores: a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
              idColaboradores: a.colaboradores[0]._id,
              user: a.user[0].nombre + " " + a.user[0].apellido,
              idUser: a.user[0]._id,
              tipo:"Surtido",
              saldo: a.surtidos[0].saldo
            }}else if(a.notasCargo!=""){
              return {
                _id: a._id,
                numero: a.idAbono,
                fecha: a.fecha,
                clientes: a.clientes[0].nombre_comercial,
                idClientes: a.clientes[0]._id,
                bancos: a.bancos[0].banco + " " + a.bancos[0].cuenta,
                idBancos: a.bancos[0]._id,
                formaPago: a.formaPago,
                observaciones: a.observaciones,
                concepto: a.concepto,
                importe: a.importe,
                nota: "NC" + " " + a.notasCargo[0].idNotaCargo,
                idNota: a.notasCargo[0]._id,
                editado:a.editado,
                is_active: a.is_active,
                codigoAgrupado: a.codigoAgrupado,
                validado:a.validado,
                colaboradores: a.clientes[0].contacto[0].nombre + " " + a.clientes[0].contacto[0].apellido,
                idColaboradores: a.clientes[0].contacto[0]._id,
                user: a.user[0].nombre + " " + a.user[0].apellido,
                idUser: a.user[0]._id,
                tipo:"NC",
                saldo: a.notasCargo[0].saldo
              }
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);

        let abonosAgrupado = data.reduce(function (groups, item) {
          const val = item["codigoAgrupado"] + item["fecha"];
          groups[val] = groups[val] || {
            codigoAgrupado: item.codigoAgrupado,
            fecha: item.fecha,
            importe: 0,
          };
          groups[val]._id = item._id;
          groups[val].numero = item.numero;
          groups[val].fecha = item.fecha;
          groups[val].clientes = item.clientes;
          groups[val].idClientes = item.idClientes;
          groups[val].bancos = item.bancos;
          groups[val].idBancos = item.idBancos;
          groups[val].formaPago = item.formaPago;
          groups[val].observaciones = item.observaciones;
          groups[val].concepto = item.concepto;
          groups[val].importe += item.importe;
          groups[val].nota = item.nota;
          groups[val].idNota = item.idNota;
          groups[val].editado = item.editado;
          groups[val].is_active = item.is_active;
          groups[val].codigoAgrupado = item.codigoAgrupado;
          groups[val].validado = item.validado;
          groups[val].colaboradores = item.colaboradores;
          groups[val].idColaboradores = item.idColaboradores;
          groups[val].user = item.user;
          groups[val].idUser = item.idUser;
          groups[val].tipo = item.tipo;
          groups[val].saldo = item.saldo;
          return groups;
        }, []);
  
        let abonosAgrupado2 = Object.values(abonosAgrupado);


        setAbonos(abonosAgrupado2);
        setComments(abonosAgrupado2);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_USERS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allUsers = response.data;
        setUsers(allUsers);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });


    axios
      .get(URL_BANCOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allBancos = response.data;
        setBancos(allBancos);
      })
      .catch((err) => {
        console.log(err);
      });

  }, [ selectedFechaInicio, selectedFechaFin]);


  function PDFTabla() {
    let TotalImporte= 0
    const data = comments
    .filter(
      (comment) =>
        comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
        comment.user.toLowerCase().includes(search.toLowerCase()) ||
        comment.colaboradores.toLowerCase().includes(search.toLowerCase()) ||
        comment.colaborador.toLowerCase().includes(search.toLowerCase()) ||
        comment.bancos.toLowerCase().includes(search.toLowerCase()) ||
        comment.formaPago.toLowerCase().includes(search.toLowerCase()) ||
        comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
        comment.concepto.toLowerCase().includes(search.toLowerCase()) ||
        comment.importe.toString().includes(search) ||
        comment.numero.toString().includes(search) 
    )
    .sort((a, b) => (a.idAbono > b.idAbono ? 1 : -1))
    .map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedUser == 0 || selectedUser == a.idUser) &&
        (selectedColaborador == 0 || selectedColaborador == a.idColaboradores) &&
        (validado == 0 || validado == a.validado) &&
        (selectedBanco == 0 || selectedBanco == a.idBancos) &&
        (formaPago == 0 || formaPago == a.formaPago)&&
        (concepto == 0 || concepto == a.concepto) &&
        (activo == 0 || activo == a.is_active)
      ) {
        TotalImporte = TotalImporte + a.importe
        return [
          a.numero,
          a.fecha,
          a.user,
          a.clientes,
          a.colaboradores,
          a.bancos,
          a.concepto,
          a.formaPago,
          a.observaciones,
          a.validado,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.importe),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });


    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Abonos `, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Usuario",
          "Cliente",
          "Vendedor",
          "Banco",
          "Concepto",
          "Forma Pago",
          "Observaciones",
          "Validado",
          "Importe",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(TotalImporte)
        ]
      ]
    });
    doc.save(`Abonos.pdf`);
  }

  function excel() {
    const dataExcel = comments
    .filter(
      (comment) =>
        comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
        comment.user.toLowerCase().includes(search.toLowerCase()) ||
        comment.colaboradores.toLowerCase().includes(search.toLowerCase()) ||
        comment.bancos.toLowerCase().includes(search.toLowerCase()) ||
        comment.formaPago.toLowerCase().includes(search.toLowerCase()) ||
        comment.concepto.toLowerCase().includes(search.toLowerCase()) ||
        comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
        comment.importe.toString().includes(search) ||
        comment.numero.toString().includes(search) 
    )
    .sort((a, b) => (a.idAbono > b.idAbono ? 1 : -1))
    .map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedUser == 0 || selectedUser == a.idUser) &&
        (selectedColaborador == 0 || selectedColaborador == a.idColaboradores) &&
        (validado == 0 || validado == a.validado) &&
        (selectedBanco == 0 || selectedBanco == a.idBancos) &&
        (formaPago == 0 || formaPago == a.formaPago) &&
        (concepto == 0 || concepto == a.concepto) &&
        (activo == 0 || activo == a.is_active)
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          Usuario: a.user,
          Cliente: a.clientes,
          Vendedor: a.colaboradores,
          Banco: a.bancos,
          Concepto: a.concepto,
          FormaPago: a.formaPago,
          Observaciones: a.observaciones,
          Validado: a.validado,
          Importe: a.importe,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoAbonosAgrupado";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoAbonosAgrupado",
        sheetFilter: [
          "Numero",
          "Fecha",
          "Usuario",
          "Cliente",
          "Vendedor",
          "Banco",
          "Concepto",
          "FormaPago",
          "Observaciones",
          "Validado",
          "Importe",
          ],
        sheetHeader: [
          "Numero",
          "Fecha",
          "Usuario",
          "Cliente",
          "Vendedor",
          "Banco",
          "Concepto",
          "FormaPago",
          "Observaciones",
          "Validado",
          "Importe",
          ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let TotalImporte= 0
    const data = comments
    .filter(
      (comment) =>
        comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
        comment.user.toLowerCase().includes(search.toLowerCase()) ||
        comment.colaboradores.toLowerCase().includes(search.toLowerCase()) ||
        comment.bancos.toLowerCase().includes(search.toLowerCase()) ||
        comment.formaPago.toLowerCase().includes(search.toLowerCase()) ||
        comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
        comment.concepto.toLowerCase().includes(search.toLowerCase()) ||
        comment.importe.toString().includes(search) ||
        comment.numero.toString().includes(search) 
    )
    .sort((a, b) => (a.idAbono > b.idAbono ? 1 : -1))
    .map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedUser == 0 || selectedUser == a.idUser) &&
        (selectedColaborador == 0 || selectedColaborador == a.idColaboradores) &&
        (validado == 0 || validado == a.validado) &&
        (selectedBanco == 0 || selectedBanco == a.idBancos) &&
        (formaPago == 0 || formaPago == a.formaPago)&&
        (concepto == 0 || concepto == a.concepto) &&
        (activo == 0 || activo == a.is_active)
      ) {
        TotalImporte = TotalImporte + a.importe
        return [
          a.numero,
          a.fecha,
          a.user,
          a.clientes,
          a.colaboradores,
          a.bancos,
          a.concepto,
          a.formaPago,
          a.observaciones,
          a.validado,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.importe),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });


    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Abonos `, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Usuario",
          "Cliente",
          "Vendedor",
          "Banco",
          "Concepto",
          "Forma Pago",
          "Observaciones",
          "Validado",
          "Importe",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(TotalImporte)
        ]
      ]
    });
    
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Abonos",
          email: mailTo,
          fileName: "ListadoAbonosAgrupado.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Abonos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  async function validarSurtido(
    idValida,
    idNota,
    importe,
    idClientes,
    tipo,
    saldo
  ) {
    setValidaBoton(false);

    
    // if (saldo >= importe){
      if (tipo == "Surtido") {
        await axios
          .patch(
            `${URL_ABONOS}AplicarSurtido/${idValida}`,
            {
              validado: "Si",
              surtidos: idNota,
              importe: importe,
              clientes: idClientes,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            // toggle();
            Swal.fire("Good job!", "Actualizado con exito", "success");
            const newComments = comments.map((i) => {
              if (idValida === i._id) {
                i.validado = "Si"
              }
              if(idNota == i.idNota){
                i.saldo = saldo - importe
              }
              return i;
            });
            setComments(newComments);
            setValidaBoton(true);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      } else {
        await axios
          .patch(
            `${URL_ABONOS}AplicarNota/${idValida}`,
            {
              validado: "Si",
              notasCargo: idNota,
              importe: importe,
              clientes: idClientes,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            // toggle();
            Swal.fire("Good job!", "Actualizado con exito", "success");
            const newComments = comments.map((i) => {
              if (idValida === i._id) {
                i.validado = "Si"
              }
              // if(idNota == i.idNota){
              //   i.saldo = saldo - importe
              // }
              return i;
            });
            setComments(newComments);
            setValidaBoton(true);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }

      // } else{
      //   Swal.fire({
      //     icon: "error",
      //     title: "Oops...",
      //     text: "Something went wrong!",
      //     footer: `El Importe es mayor al Saldo`,
      //   });
      // }
  }

 

  async function PDFOC(
    fecha,
    clientes,
    importe,
    nota,
    formaPago,
    observaciones,
    numero
  ) {

       
        const doc = new jsPDF();
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 150, 10, 35, 10);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 95, 15, 30, 30);
        doc.setFontSize(16);
        doc.text(`${process.env.REACT_APP_NOMBRE_INSTITUCION}`, 90, 53);
        doc.text(`___________________`, 80, 100);
        doc.text(`Firma`, 100, 110);
      
        doc.autoTable({
          head: [
            [
              "No. Recibo",
              "Fecha",
              "Cliente",
              "Nota",
              "Forma Pago",
              "Observaciones",
              "Importe",
            ],
          ],
          body: [[
            numero,
            fecha,
            clientes,
            nota,
            formaPago,
            observaciones,
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(importe)
          ]],
          startY: 60,
        
        });

        doc.save(`Abono-${numero}.pdf`);

  }



  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Usuario", field: "user", sortable: true },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Colaborador", field: "colaboradores", sortable: true },
    { name: "Banco", field: "banco", sortable: true },
    { name: "Concepto", field: "concepto", sortable: true },
    { name: "Forma Pago", field: "formaPago", sortable: true },
    { name: "Observaciones", field: "observaciones", sortable: true },
    { name: "Nota", field: "nota", sortable: true },
    { name: "Importe", field: "importe", sortable: true },
    // { name: "Validado", field: "validado", sortable: false },
    // { name: "Recibo", field: "recibo", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.user.toLowerCase().includes(search.toLowerCase()) ||
          comment.colaboradores.toLowerCase().includes(search.toLowerCase()) ||
          comment.bancos.toLowerCase().includes(search.toLowerCase()) ||
          comment.formaPago.toLowerCase().includes(search.toLowerCase()) ||
          comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
          comment.concepto.toLowerCase().includes(search.toLowerCase()) ||
          comment.importe.toString().includes(search) ||
          comment.nota.toString().includes(search) ||
          comment.numero.toString().includes(search) 
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedUser) {
      computedComments = computedComments.filter((e) =>
        e.idUser.includes(selectedUser)
      );
    }

    if (selectedColaborador) {
      computedComments = computedComments.filter((e) =>
        e.idColaboradores.includes(selectedColaborador)
      );
    }

    if (selectedBanco) {
      computedComments = computedComments.filter((e) =>
        e.idBancos.includes(selectedBanco)
      );
    }
    if (formaPago) {
      computedComments = computedComments.filter((e) =>
        e.formaPago.includes(formaPago)
      );
    }
    if (concepto) {
      computedComments = computedComments.filter((e) =>
        e.concepto.includes(concepto)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    if (validado) {
      computedComments = computedComments.filter((e) => 
        e.validado.includes(validado)
      );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.is_active.includes(activo)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "importe" &&
      sorting.field != "nota" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "importe" ||
      sorting.field == "nota" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "importe" ||
      sorting.field == "nota" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedUser,
    selectedColaborador,
    selectedFechaInicio,
    selectedFechaFin,
    selectedBanco,
    validado,
    formaPago,
    concepto,
    activo
  ]);

  function EditAbono(
    _id,
    numero,
    fecha,
    idClientes,
    importe,
    formaPago,
    cliente,
    idNota,
    observaciones,
    concepto
  ) {
    setIdAbonoEdit(_id);
    setNumeroEdit(numero);
    setFechaEdit(fecha);
    setIdClienteEdit(idClientes);
    setImporteEdit(importe);
    setImporteEditViejo(importe);
    setFormaPagoEdit(formaPago);
    setClienteEdit(cliente);
    setNotaEdit(idNota);
    setObservacionesEdit(observaciones);
    setConceptoEdit(concepto);
    toggleEdit();
  }

  function EditAbono2(
    _id,
    numero,
    fecha,
    idClientes,
    importe,
    formaPago,
    cliente,
    idNota,
    observaciones,
    concepto
  ) {
    setIdAbonoEdit(_id);
    setNumeroEdit(numero);
    setFechaEdit(fecha);
    setIdClienteEdit(idClientes);
    setImporteEdit(importe);
    setImporteEditViejo(importe);
    setFormaPagoEdit(formaPago);
    setClienteEdit(cliente);
    setNotaEdit(idNota);
    setObservacionesEdit(observaciones);
    setConceptoEdit(concepto);
    toggleEdit2();
  }

  
  const EditAbonoSave = (event) => {
    event.preventDefault();
    Swal.fire({
      title: "Estas seguro?",
      text: "Se registrará el abono",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Enviar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${URL_ABONOS}/${idAbonoEdit}`,
            {
              importe: parseFloat(importeEdit) - parseFloat(importeEditViejo),
              fecha: fechaEdit,
              editado:"Si",
              observaciones: observacionesEdit,
              concepto: conceptoEdit,
              idCliente: idClienteEdit,
              notaEdit,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            axios.post(
              URL_LOGS,
              {
                tipo: "Editar Abono",
                detalle: `${clienteEdit}`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
            Swal.fire("Good job!", "Creado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  };

  function cancelarAplicado(idAbono) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se cancelara el Aplicado!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
    axios
      .get(`${URL_ABONOS}CancelAplicado/${idAbono}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
    }
  });
  }

  function cancelarAbono(idAbono) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se cancelara el Abono!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
    axios
      .get(`${URL_ABONOS}CancelAbono/${idAbono}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
    }
  });
  }

  //   function desarrollo(){
  //   axios
  //     .get(`${URL_ABONOS}Desarrollo`, {
  //       headers: {
  //         Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //       },
  //     })
  //     .then((response) => {
  //       Swal.fire("Good job!", "Enviado con exito", "success");
  //     })
  //     .catch((err) => {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: "Something went wrong!",
  //         footer: `${err.response.data}`,
  //       });
  //       console.log(err);
  //     });
  // }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_admin ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.menu_admin ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/AbonosCreate"
                >
                  Nuevo Abono
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Abono
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuAdmin"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Abonos</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Reporte de Abonos</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Validado</Label>
              <Input
                bsSize="sm"
                type="select"
                value={validado}
                required
                onChange={(e) => {
                  setValidado(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                </Input>
            </Col>
            <Col md={2}>
              <Label>Activo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={activo}
                required
                onChange={(e) => {
                  setActivo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Activos</option>
                <option value="No">Inactivos</option>
              </Input>
            </Col>

          
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td></td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedUser}
                onChange={(e) => {
                  setSelectedUser(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {users
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.nombre} {a.apellido}</option>;
                  })}
              </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedCliente}
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {clientes
                  .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedColaborador}
                onChange={(e) => {
                  setSelectedColaborador(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.nombre} {a.apellido}</option>;
                  })}
              </Input>
              </td>

              <td style={{ paddingTop: "0px" }}>
              <Input
                      type="select"
                      bsSize="sm"
                      value={selectedBanco}
                      onChange={(e) => {
                        setSelectedBanco(e.target.value);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {bancos
                        .sort((a, b) => (a.banco > b.banco ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.banco} {a.cuenta}
                            </option>
                          );
                        })}
                    </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                      type="select"
                      bsSize="sm"
                      value={concepto}
                      onChange={(e) => {
                        setConcepto(e.target.value);
                      }}
                    >
                      <option value="">Selecciona</option>
                      <option value="Abono">Abono</option>
                      <option value="Bonificacion">Bonificacion</option>
                      <option value="Devolucion">Devolucion</option>
                    </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                      type="select"
                      bsSize="sm"
                      value={formaPago}
                      onChange={(e) => {
                        setFormaPago(e.target.value);
                      }}
                    >
                      <option value="">Selecciona</option>
                      <option value="Efectivo">Efectivo</option>
                      <option value="Transfer">Transfer</option>
                      <option value="TDC">TDC</option>
                    </Input>
              </td>
            </tr>
            <tbody>
              {commentsData.map((a) => {
                if(a.is_active == "No"){
                  return (
                    <tr style={{ backgroundColor: "#ed8c8c" }}>
                      <td>{a.numero}</td>
                      <td  style={{ minWidth: 100 }}>{a.fecha}</td>
                      <td>{a.user}</td>
                      <td>{a.clientes}</td>
                      <td>{a.colaboradores}</td>
                      <td>{a.bancos}</td>
                      <td>{a.concepto}</td>
                      <td>{a.formaPago}</td>
                      <td>{a.observaciones}</td>
                      <td>{a.nota}</td>
                      {/* <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.importe)}</td>
                      <td>{a.validado}</td> */}
                      <td style={{ minWidth: 120 }}>CANCELADO</td>
                    </tr>
                  );
                }else{
                    return (
                      <tr>
                        <td>{a.numero}</td>
                        <td  style={{ minWidth: 100 }}>{a.fecha}</td>
                        <td>{a.user}</td>
                        <td>{a.clientes}</td>
                        <td>{a.colaboradores}</td>
                        <td>{a.bancos}</td>
                        <td>{a.concepto}</td>
                        <td>{a.formaPago}</td>
                        <td>{a.observaciones}</td>
                      <td>{a.nota}</td>
                        <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.importe)}</td>
                        {/* <td>{a.validado}</td>
                        <td style={{ minWidth: 120 }}>
                          {a.validado == "No" ? (
                            <>
                        {user.autorizarPago ? (
                            <Button
                            size="sm"
                            className="btn"
                            color="success"
                            onClick={(e) =>validarSurtido(a._id, a.idNota, a.importe, a.idClientes, a.tipo, a.saldo)}
                          >
                            <i class="far fa-check-circle"></i>
                          </Button>
                        ): undefined}
                           <Button
                                size="sm"
                                className="btn"
                                color="info"
                                onClick={(e) =>
                                  EditAbono(
                                    a._id,
                                    a.numero,
                                    a.fecha,
                                    a.idClientes,
                                    a.importe,
                                    a.formaPago,
                                    a.clientes,
                                    a.idNota,
                                    a.observaciones,
                                    a.concepto
                                  )
                                }
                              >
                                <i class="far fa-edit"></i>
                              </Button>
                              <Button
                                  size="sm"
                                  className="btn"
                                  color="danger"
                                  onClick={(e) => cancelarAbono(a._id)}
                                >
                                  <i class="fas fa-ban"></i>
                                </Button>
                                </>
                          ) : (
                            <>
                            <Button
                                size="sm"
                                className="btn"
                                color="info"
                                onClick={(e) =>
                                  EditAbono2(
                                    a._id,
                                    a.numero,
                                    a.fecha,
                                    a.idClientes,
                                    a.importe,
                                    a.formaPago,
                                    a.clientes,
                                    a.idNota,
                                    a.observaciones,
                                    a.concepto
                                  )
                                }
                              >
                                <i class="far fa-edit"></i>
                              </Button>
                            {user.autorizarPago ? (
                              <Button
                                  size="sm"
                                  className="btn"
                                  color="danger"
                                  onClick={(e) => cancelarAplicado(a._id)}
                                >
                                  <i class="fas fa-ban"></i>
                                </Button>
                                ):undefined}
                            </>
                          )}
                        
                              <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                onClick={(e) =>
                                  PDFOC(
                                    a.fecha,
                                    a.clientes,
                                    a.importe,
                                    a.nota,
                                    a.formaPago,
                                    a.observaciones,
                                    a.numero
                                  )
                                }
                              >
                                <i class="far fa-file-pdf"></i>
                              </Button>
                             
                        </td> */}
                      
                      </tr>
                    );
                }
 
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />
      <Modal size="sm" isOpen={modalEdit} toggle={toggleEdit}>
            <ModalHeader toggle={toggleEdit}>
              <h4>Editar Abono {numeroEdit} {clienteEdit}</h4>
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={EditAbonoSave}>
                <Row>
                  <Col md={6}>
                    <Label>Fecha</Label>
                    <Input
                      type="date"
                      value={fechaEdit}
                      required
                      onChange={(e) => {
                        setFechaEdit(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <Label>Importe</Label>
                    <Input
                      className="col-sm-12"
                      type="number"
                      placeholder="Importe"
                      value={importeEdit}
                      required
                      onChange={(e) => {
                        setImporteEdit(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                <Col md={6}>
                  <Label className="mr-sm-2">Concepto</Label>
                  <Input
                    type="select"
                    value={conceptoEdit}
                    onChange={(e) => {
                      setConceptoEdit(e.target.value);
                    }}
                  >
                    <option value="">Selecciona un Concepto</option>
                    <option value="Abono">Abono</option>
                    <option value="Bonificacion">Bonificacion</option>
                  </Input>
                </Col>
                <Col md={6}>
                    <Label>Observaciones</Label>
                    <Input
                      type="text"
                      value={observacionesEdit}
                      required
                      onChange={(e) => {
                        setObservacionesEdit(e.target.value);
                      }}
                    />
                  </Col>
                </Row>

                <br />
                <Row>
                    <Button type="submit" className="btn btn-success">
                      Guardar
                    </Button>

                  <div>
                    <SweetAlert
                      show={text}
                      title="Creado con Exito"
                      onConfirm={() => {
                        setText(false);
                      }}
                    />
                  </div>
                </Row>
              </Form>
            </ModalBody>
          </Modal>

          <Modal size="sm" isOpen={modalEdit2} toggle={toggleEdit2}>
            <ModalHeader toggle={toggleEdit2}>
              <h4>Editar Abono {numeroEdit} {clienteEdit}</h4>
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={EditAbonoSave}>
                <Row>
                  <Col md={6}>
                    <Label>Fecha</Label>
                    <Input
                      type="date"
                      value={fechaEdit}
                      disabled
                    />
                  </Col>
                  <Col md={6}>
                    <Label>Importe</Label>
                    <Input
                      className="col-sm-12"
                      type="number"
                      placeholder="Importe"
                      value={importeEdit}
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                <Col md={6}>
                  <Label className="mr-sm-2">Concepto</Label>
                  <Input
                    type="select"
                    value={conceptoEdit}
                    onChange={(e) => {
                      setConceptoEdit(e.target.value);
                    }}
                  >
                    <option value="">Selecciona un Concepto</option>
                    <option value="Abono">Abono</option>
                    <option value="Bonificacion">Bonificacion</option>
                  </Input>
                </Col>
                <Col md={6}>
                    <Label>Observaciones</Label>
                    <Input
                      type="text"
                      value={observacionesEdit}
                      required
                      onChange={(e) => {
                        setObservacionesEdit(e.target.value);
                      }}
                    />
                  </Col>
                </Row>

                <br />
                <Row>
                    <Button type="submit" className="btn btn-success">
                      Guardar
                    </Button>

                  <div>
                    <SweetAlert
                      show={text}
                      title="Creado con Exito"
                      onConfirm={() => {
                        setText(false);
                      }}
                    />
                  </div>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
     
      {loader}
    </>
  );
}

export default ListadoAbonosAgrupado;
