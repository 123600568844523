import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
  Badge
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import BajaMultiple from "../../BajaMultiple";
import StatusMultiple from "../../StatusMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import moment from 'moment';
import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";

function ListadoDevolucionesAdmin() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_DEVOLUCIONES = process.env.REACT_APP_URL_DEVOLUCIONES;
  const URL_DEVOLUCIONES_ACUMULADOS = process.env.REACT_APP_URL_DEVOLUCIONES_ACUMULADOS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ARTICULOS_DEVOLUCIONES = process.env.REACT_APP_URL_ARTICULOS_DEVOLUCIONES;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_ABONOS = process.env.REACT_APP_URL_ABONOS;
  const BANCO_DEVOLUCION = process.env.REACT_APP_BANCO_DEVOLUCION;
  const URL_NOTAS_CARGO = process.env.REACT_APP_URL_NOTAS_CARGO;

  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-30);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [idEdit, setIdEdit] = useState("");
  const [articulosDev, setArticulosDev] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");

  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedAplicada, setSelectedAplicada] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);
  const [vendedor, setVendedor] = useState("");
  const [codigoAgrupado, setCodigoAgrupado] = useState(uuidv4());

  const [modalEditArticulos, setModalEditArticulos] = useState(false);
  const toggleEditArticulos = () => setModalEditArticulos(!modalEditArticulos);
  const [total_general_edit, setTotalGeneralEdit] = useState(0);
  const [impuestos, setImpuestos] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [iva, setIva] = useState(0);

  const [numero, setNumero] = useState("");
  const [clienteName, setClienteName] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");
  const [validaBoton, setValidaBoton] = useState(true);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [modalAplicaDevolucion, setModalAplicaDevolucion] = useState(false);
  const toggleAplicaDevolucion = () => setModalAplicaDevolucion(!modalAplicaDevolucion);
  const [cliente, setCliente] = useState("");
  const [inputFields, setInputFields] = useState([]);
  const [inputFieldsNC, setInputFieldsNC] = useState([]);
  const [total, setTotal] = useState(0);

  const [total_kilos_edit, setTotalKilosEdit] = useState(0);
  const [total_metros_edit, setTotalMetrosEdit] = useState(0);

  const [validado, setValidado] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(()=>{

    axios
      .get(`${URL_DEVOLUCIONES}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDevoluciones = response.data;
        // Array para pagination
        let arrayTabla = allDevoluciones
          // .sort((a, b) => (a.idDevolucion > b.idDevolucion ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              numero: a.idDevolucion,
              activo: a.is_active,
              fecha: a.fecha,
              colaboradores: a.surtidos[0].pedidos[0].colaboradores[0].nombre + " " + a.surtidos[0].pedidos[0].colaboradores[0].apellido,
              idColaboradores: a.surtidos[0].pedidos[0].colaboradores[0]._id,
              observaciones: a.observaciones,
              subTotal: a.subTotal,
              iva: a.iva,
              total_general: a.total_general,
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              total_cantidad: a.total_cantidad,
              impuestos: a.impuestos,
              aplicada: a.aplicada,
              surtidos:a.surtidos[0].idSurtido,
              validado:a.validado,
              validadoAplicada:a.validadoAplicada
              // area: a.areas[0].name,
              // aplicada: a.aplicada
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);
        setComments(data);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });

  }, [selectedFechaInicio, selectedFechaFin]);

  const ListaDevoluciones = comments.sort((a, b) =>
    a.idDevolucion > b.idDevolucion ? 1 : -1
  );

  function PDFTabla() {
    let TotalTabla = 0
    let subTotalTabla = 0
    let ivaTabla = 0
    let TotalPiezas = 0
    const data = ListaDevoluciones.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (validado == 0 || validado == a.validado) &&
        (selectedColaborador == 0 || selectedColaborador == a.idColaboradores) &&
        (selectedAplicada == 0 || selectedAplicada == a.aplicada)
      ) {
        TotalTabla = TotalTabla + a.total_general
        subTotalTabla = subTotalTabla + a.subTotal
        ivaTabla = ivaTabla + a.iva
        TotalPiezas = TotalPiezas + a.total_cantidad
        return [
          a.numero,
          a.fecha,
          a.colaboradores,
          a.clientes,
          new Intl.NumberFormat("en-US", {maximumFractionDigits: 2}).format(a.total_cantidad),
          a.validado,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.subTotal),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.iva),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.total_general),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });


    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Devoluciones`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Validado",
          "SubTotal",
          "IVa",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {maximumFractionDigits: 2}).format(TotalPiezas),
          "",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(subTotalTabla),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(ivaTabla),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(TotalTabla),
        ]
      ],
      showFoot: "lastPage",
    });
    doc.save(`Devoluciones.pdf`);
  }

  function excel() {
    const dataExcel = ListaDevoluciones.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (validado == 0 || validado == a.validado) &&
        (selectedColaborador == 0 || selectedColaborador == a.idColaboradores)&&
        (selectedAplicada == 0 || selectedAplicada == a.aplicada)
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          Vendedor: a.colaboradores,
          Cliente: a.clientes,
          Cantidad: a.total_cantidad,
          Validado: a.validado,
          SubTotal: a.subTotal,
          IVA: a.iva,
          Total: a.total_general,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoDevoluciones";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoDevoluciones",
        sheetFilter: [
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Validado",
          "SubTotal",
          "IVA",
          "Total",
        ],
        sheetHeader: [
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Validado",
          "SubTotal",
          "IVA",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let TotalTabla = 0
    let TotalPiezas = 0
    const data = ListaDevoluciones.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (validado == 0 || validado == a.validado) &&
        (selectedColaborador == 0 || selectedColaborador == a.idColaboradores)&&
        (selectedAplicada == 0 || selectedAplicada == a.aplicada)
      ) {
        TotalTabla = TotalTabla + a.total_general
        TotalPiezas = TotalPiezas + a.total_cantidad
        return [
          a.numero,
          a.fecha,
          a.colaboradores,
          a.clientes,
          a.total_cantidad,
          a.validado,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.total_general),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });


    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Devoluciones`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Validado",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US").format(TotalPiezas),
          "",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(TotalTabla),
        ]
      ]
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Devoluciones",
          email: mailTo,
          fileName: "ListadoDevoluciones.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Devoluciones.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function EditDevolucion(idDev, total_general, impuestos, cliente, numero, subTotal, iva) {
   
    setIdEdit(idDev);
    setTotalGeneralEdit(total_general)
    setImpuestos(impuestos)
    setClienteName(cliente)
    setNumero(numero)
    setSubTotal(subTotal)
    setIva(iva)
    axios
      .get(`${URL_ARTICULOS_DEVOLUCIONES}/devolucion/${idDev}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data;
        let arrayTabla = allArticulosPedido
          .map((a) => {
            return {
              id: a._id,
              articuloName: a.articulos[0].nombre + " / " + a.articulos[0].codigo,
              totalPiezas: a.totalPiezas,
              precio: a.precio,
              total: a.total,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setArticulosDev(dataFinal);

      })
      .catch((err) => {
        console.log(err);
      });
    toggleEditArticulos();
  }

 

  async function PDFOC(
    fecha,
    clientes,
    numero,
    idPDFOC,
    impuestos,
    area,
    subTotal,
    iva,
    total_general,
    total_cantidad,
    surtidos
  ) {
    await axios
      .get(`${URL_DEVOLUCIONES_ACUMULADOS}/devolucion/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosDevolucion = response.data;
        let arrayTabla = allArticulosDevolucion
          .map((a) => {
            return {
              id:a._id,
              articulos: a.articulos[0].codigo + " " + a.articulos[0].nombre,
              idColor: a.colores[0]._id,
              colores: a.colores[0].name,
              unidad: a.articulos[0].unidad,
              cantidad: a.cantidad,
              tono: a.tono,
              precio: a.venta / a.cantidad,
             };
          })
          .filter(function (el) {
            return el != null;
          });

          let dataFinal = Object.values(arrayTabla); 
    
        let mts = dataFinal.filter(e=>e.unidad == "Metros")
        let totalMts = mts.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalMetrosEdit(TM);
        let KG = dataFinal.filter(e=>e.unidad == "Kilos")
        let totalKG = KG.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);
        setTotalKilosEdit(TK);

        let total = 0

        const data = dataFinal.map((a) => {
          total = a.precio * a.cantidad
          return [
            a.articulos,
            a.colores,
            a.tono,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.precio),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(total),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });

        const doc = new jsPDF("landscape");
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 30, 45, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 120, 15, 60, 25);
        doc.text(`Devolución # ${numero}`, 20, 20);
        doc.setFontSize(12)
        doc.text(`Fecha ${fecha}`, 20, 25);
        doc.text(`Cliente ${clientes}`, 20, 30);
        doc.text(`Surtido # ${surtidos}`, 20, 35);
        doc.text(`Metros ${new Intl.NumberFormat("en-US").format(TM)} / Kilos ${new Intl.NumberFormat("en-US").format(TK)}`, 20, 40)
        doc.autoTable({
          head: [["Articulo","Color", "Tono", "Cantidad", "Precio", "Total"]],
          body: dataPDFLimpia,
          startY: 45,
          foot: [
            [
              "",
              "",
              "",
              "",
              "Sub Total",
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(subTotal),
            ],
            [
              "",
              "",
              "",
              "",
              `IVA ${impuestos}%`,
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(iva),
            ],
            [
              "",
              "",
              "",
              "",
              "Total",
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(total_general),
            ],
          ],
          showFoot: "lastPage",
        });

        doc.save(`Devolucion-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }



  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Vendedor", field: "colaboradores", sortable: false },
    { name: "Cantidad", field: "total_cantidad", sortable: true },
    { name: "SubTotal", field: "subTotal", sortable: true },
    { name: "iva", field: "iva", sortable: true },
    { name: "Total", field: "total_general", sortable: true },
    { name: "Aplicada", field: "aplicada", sortable: true },
    { name: "Validado", field: "validado", sortable: false },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.aplicada.toLowerCase().includes(search.toLowerCase()) ||
          comment.total_cantidad.toString().includes(search) ||
          comment.subTotal.toString().includes(search) ||
          comment.iva.toString().includes(search) ||
          comment.total_general.toString().includes(search) ||
          comment.numero.toString().includes(search) 
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedColaborador) {
      computedComments = computedComments.filter((e) =>
        e.idColaboradores.includes(selectedColaborador)
      );
    }
    if (selectedAplicada) {
      computedComments = computedComments.filter((e) =>
        e.aplicada.includes(selectedAplicada)
      );
    }
    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    if (validado) {
      computedComments = computedComments.filter(
        (e) => e.validado <= validado
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "total_cantidad" &&
      sorting.field != "total_general" &&
      sorting.field != "subTotal" &&
      sorting.field != "iva" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "total_cantidad" ||
        sorting.field == "total_general" ||
        sorting.field == "subTotal" ||
        sorting.field == "iva" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "total_cantidad" ||
        sorting.field == "total_general" ||
        sorting.field == "subTotal" ||
        sorting.field == "iva" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    validado,
    selectedCliente,
    selectedColaborador,
    selectedAplicada
  ]);

  const handleChangeInput = (id, event) => {
    const newArticulosDev = articulosDev.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        i.total = parseFloat(event.target.value) * parseFloat(i.totalPiezas)
      }
      return i;
    });
    setArticulosDev(newArticulosDev);
    ActualizaTotalesPrecio()
  };

  function ActualizaTotalesPrecio() {

    let importes = articulosDev.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (impuestos != 0) {
      setIva((impuestos * TG) / 100);
    } else {
      setIva(0);
    }
    let totTemp = (impuestos * TG) / 100 + TG 
  
    setTotalGeneralEdit(totTemp);
  
  }

  const saveDevolucion = async (event) => {
    event.preventDefault();

      toggleProgreso();
      let totalPedido = articulosDev.length;

      try {
        await axios
          .patch(
              `${URL_DEVOLUCIONES}/${idEdit}`,
            {
              subTotal,
              iva,
              total_general: total_general_edit
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {

            articulosDev.map((a) => {
                axios
                  .patch(
                    `${URL_ARTICULOS_DEVOLUCIONES}/${a.id}`,
                    {
                      precio: parseFloat(a.precio),
                      total: parseFloat(a.total),
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalPedido = totalPedido - 1;
                    if (totalPedido == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Devolucion",
                            detalle: `${numero}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", `success`);
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        });
                    }
                  });

            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response}`,
            });
            console.log(error);
            setValidaBoton(true);
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response}`,
        });
        console.log(error);
      }
  }

  function aplicarDevolucion(idDev, total_general, numero, idClientes, cliente, idColaboradores){
    setIdEdit(idDev);
    setTotalGeneralEdit(total_general)
    setCliente(idClientes)
    setClienteName(cliente)
    setNumero(numero)
    setTotal(0)
    setVendedor(idColaboradores)

    axios
    .get(`${URL_SURTIDOS}Cliente/${idClientes}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allCargos = response.data
      let arrayTabla = allCargos
        .map((a) => {
          return {
            id: a._id,
            fecha: a.fecha,
            total_general: a.total_general,
            saldo: a.saldo,
            nota: a.idSurtido,
            importe: 0,
            clientes: a.clientes[0]._id,
            observaciones: "NA"
          };
        }).filter(function (el) {
          return el != null;
        });
        let dataFinal = Object.values(arrayTabla);
        setInputFields(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      })

      axios
      .get(`${URL_NOTAS_CARGO}Cliente/${idClientes}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCargosNC = response.data
        let arrayTablaNC = allCargosNC
          .map((a) => {
            return {
              id: a._id,
              fecha: a.fecha,
              total_general: a.importe,
              saldo: a.saldo,
              nota: "NC " + a.idNotaCargo,
              importe: 0,
              clientes: a.clientes[0]._id,
              observaciones: "NA"
            };
          }).filter(function (el) {
            return el != null;
          });
          let dataFinalNC = Object.values(arrayTablaNC);
          setInputFieldsNC(dataFinalNC);
        })
        .catch((err) => {
          console.log(err);
        })

      toggleAplicaDevolucion()
  }

  const handleChangeInputImporte = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if(event.target.value <= i.saldo || event.target.value == ""){
          i[event.target.name] = parseFloat(event.target.value)
        }
        else{
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo",
          });
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };
  
  const handleChangeInputImporteNC = (id, event) => {
    const newInputFieldsNC = inputFieldsNC.map((i) => {
      if (id === i.id) {
        if(event.target.value <= i.saldo || event.target.value == ""){
          i[event.target.name] = parseFloat(event.target.value)
        }
        else{
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo",
          });
        }
      }
      return i;
    });
    setInputFieldsNC(newInputFieldsNC);
    ActualizaTotales();
  };
  
  function ActualizaTotales(){
    let TEfe = inputFields.map((c) => parseFloat(c.importe));
    let TE = TEfe.reduce((t, total, index) => t + total, 0);

    let TEfeNC = inputFieldsNC.map((c) => parseFloat(c.importe));
    let TENC = TEfeNC.reduce((t, total, index) => t + total, 0);
    setTotal(TE + TENC)
  }  

  const handleChangeInputObser = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  const handleChangeInputObserNC = (id, event) => {
    const newInputFieldsNC = inputFieldsNC.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFieldsNC(newInputFieldsNC);
  };

  function savePago() {
    if((total).toFixed(2) == total_general_edit){
      Swal.fire({
        title: "Estas seguro?",
        text: "Se registrará el abono",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Enviar!",
      }).then((result) => {
        if (result.isConfirmed) {
          toggleProgreso();
          let totalMails = inputFields.length + inputFieldsNC.length;
          inputFields.map((a) => {
            if (a.importe > 0) {
              axios
                .post(
                  URL_ABONOS,
                  {
                    fecha: endDate,
                    importe: a.importe,
                    clientes: cliente,
                    colaboradores: vendedor,
                    surtidos: a.id,
                    formaPago:"Devolucion",
                    concepto:"Devolucion",
                    bancos: BANCO_DEVOLUCION,
                    comisionPagada:"No",
                    observaciones: a.observaciones,
                    codigoAgrupado,
                    editado:"No",
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then((data) => {
                  axios
                  .patch(
                    `${URL_SURTIDOS}/${a.id}`,
                    {
                      saldo:a.saldo-a.importe
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  axios
                  .patch(
                    `${URL_DEVOLUCIONES}/${idEdit}`,
                    {
                      aplicada:"Si"
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  totalMails = totalMails - 1;
                  if (totalMails == 0) {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                  });
                  console.log(error);
                });
            } else {
              totalMails = totalMails - 1;
            }
          });


          inputFieldsNC.map((a) => {
            if (a.importe > 0) {
              axios
                .post(
                  URL_ABONOS,
                  {
                    fecha: endDate,
                    importe: a.importe,
                    clientes: cliente,
                    colaboradores: vendedor,
                    notasCargo: a.id,
                    formaPago:"Devolucion",
                    concepto:"Devolucion",
                    bancos: BANCO_DEVOLUCION,
                    comisionPagada:"No",
                    observaciones: a.observaciones,
                    codigoAgrupado,
                    editado:"No",
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then((data) => {
                  axios
                  .patch(
                    `${URL_NOTAS_CARGO}AplicarAbono/${a.id}`,
                    {
                      saldo: a.saldo - a.importe
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  axios
                  .patch(
                    `${URL_DEVOLUCIONES}/${idEdit}`,
                    {
                      aplicada:"Si"
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  totalMails = totalMails - 1;
                  if (totalMails == 0) {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                  });
                  console.log(error);
                });
            } else {
              totalMails = totalMails - 1;
            }
          });


        }
      });

    }else{
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El Importe a aplicar no coincide con el total de la Devolucion",
      })
    }
  
  }

  async function validar(idValida){
    await axios
          .patch(
            `${URL_DEVOLUCIONES}Valida/${idValida}`,
            {
              validado: "Si",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            // toggle();
            Swal.fire("Good job!", "Actualizado con exito", "success");
            const newComments = comments.map((i) => {
              if (idValida === i._id) {
                i.validado = "Si";
              }
              return i;
            });
            setComments(newComments);
          })
           .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
  }

  async function validarAplicada(idValidaAplicada){
    await axios
          .patch(
            `${URL_DEVOLUCIONES}Valida/${idValidaAplicada}`,
            {
              validadoAplicada: "Si",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            // toggle();
            Swal.fire("Good job!", "Actualizado con exito", "success");
            const newComments = comments.map((i) => {
              if (idValidaAplicada === i._id) {
                i.validadoAplicada = "Si";
              }
              return i;
            });
            setComments(newComments);
          })
           .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
  }
let totalDevoluciones = 0
let subTotalDevoluciones = 0
let ivaDevoluciones = 0
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_admin ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuAdmin"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Devoluciones</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Reporte de Devoluciones</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>

            <Col md={2}>
              <Label className="mr-sm-2">Validado</Label>
              <Input
                bsSize="sm"
                type="select"
                value={validado}
                required
                onChange={(e) => {
                  setValidado(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                </Input>
            </Col>
          
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td></td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedCliente}
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {clientes
                  .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedColaborador}
                onChange={(e) => {
                  setSelectedColaborador(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona un Vendedor</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  })}
              </Input>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedAplicada}
                onChange={(e) => {
                  setSelectedAplicada(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </Input>
              </td>
            </tr>
            <tbody>
              {commentsData.map((a) => {
                totalDevoluciones = totalDevoluciones + a.total_general;
                subTotalDevoluciones = subTotalDevoluciones + a.subTotal;
                ivaDevoluciones = ivaDevoluciones + a.iva;
                {
                  return (
                    <tr>
                      {/* <td>{a.activo}</td> */}
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.clientes}</td>
                      <td>{a.colaboradores}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.total_cantidad)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.subTotal)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.iva)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.total_general)}</td>
                      <td>{a.aplicada}</td>
                      <td>{a.validado}</td>
                      <td>

                            {a.aplicada == "No" && a.validado =="No"?(
                              <>

                          <Button
                            size="sm"
                            className="btn"
                            color="success"
                            onClick={(e) =>validar(a._id)}
                          >
                            <i class="far fa-check-circle"></i>
                          </Button>

                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) => EditDevolucion(a._id, a.total_general, a.impuestos, a.clientes, a.numero, a.subTotal, a.iva)}
                            >
                              <i class="fas fa-cart-plus"></i>
                            </Button>
                          </>
                      ):undefined}

                          {a.aplicada == "No" && a.validado =="Si"?(
                              <>
                            <Button
                            color="primary"
                            id="Editar"
                            size="sm"
                            onClick={(e) => aplicarDevolucion(a._id, a.total_general, a.numero, a.idClientes, a.clientes, a.idColaboradores)}
                          >
                            <i class="fas fa-dollar-sign"></i>
                          </Button>
                          </>
                      ):undefined}

                  {a.aplicada == "Si" && a.validado =="Si" && a.validadoAplicada =="No"?(
                              <>
                          <Button
                            size="sm"
                            className="btn"
                            color="success"
                            onClick={(e) =>validarAplicada(a._id)}
                          >
                           <i class="fas fa-dollar-sign"></i> <i class="far fa-check-circle"></i>
                          </Button>
                          </>
                      ):undefined}
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  a.fecha,
                                  a.clientes,
                                  a.numero,
                                  a._id,
                                  a.impuestos,
                                  a.area,
                                  a.subTotal,
                                  a.iva,
                                  a.total_general,
                                  a.total_cantidad,
                                  a.surtidos
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button>

                    </td>
                    </tr>
                  );
                }
              })}

<tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita" align="center">
                  TOTAL
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(subTotalDevoluciones)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(ivaDevoluciones)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalDevoluciones)}
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>


          <Modal
        size="xl"
        isOpen={modalEditArticulos}
        toggle={toggleEditArticulos}
      >
        <ModalHeader toggle={toggleEditArticulos}>
          <h4>Editar Precios de Devolucion #{numero}</h4>
          <h4>Cliente {clienteName}</h4>
          <h4 align="right">
            SubTotal{"  "}
            <Badge id="Total" color="danger" className="BadgeSize badge-pill">
              {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(subTotal)}
            </Badge>

            {" "}Iva{"  "}{impuestos}%{" "}
            <Badge id="Total" color="danger" className="BadgeSize badge-pill">
              {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(iva)}
            </Badge>

            {" "}Total Devolucion{"  "}
            <Badge id="Total" color="danger" className="BadgeSize badge-pill">
              {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(total_general_edit)}
            </Badge>
          </h4>
        </ModalHeader>
        <ModalBody>
        <Form onSubmit={saveDevolucion}>
          <Row>
            <Col md={4}>
            <Label className="mr-sm-2">Articulos</Label>
            </Col>
            <Col md={2}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Precio</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
          </Row>

          {articulosDev.map((ad) => (
              <div key={ad.id}>
                <Row>

                  <Col md={4}>
                    <Input
                      bsSize="sm"
                      name="articuloName"
                      type="text"
                      value={ad.articuloName}
                      required
                      disabled
                    />
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="totalPiezas"
                      type="number"
min="0"
step="any"
                      placeholder="Total Piezas"
                      value={ad.totalPiezas}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="precio"
                      type="number"
min="0"
step="any"
                      placeholder="Precio"
                      value={ad.precio}
                      required
                      onChange={(event) =>
                        handleChangeInput(ad.id, event)
                      }
                    />
                  </Col>

                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="total"
                      type="number"
min="0"
step="any"
                      placeholder="Total"
                      value={ad.total}
                      disabled
                    />
                  </Col>

                </Row>
              </div>
            ))}
            <br />
              {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
          </Form>     
        </ModalBody>
      </Modal>




      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>


       <Modal
        size="xl"
        isOpen={modalAplicaDevolucion}
        toggle={toggleAplicaDevolucion}
      >
        <ModalHeader toggle={toggleAplicaDevolucion}>
          <h4>Aplicar Devolucion #{numero}</h4>
          <h4>Cliente {clienteName}</h4>
          <h4 align="right">
          Total Devolucion{"  "}
            <Badge id="Total" color="danger" className="BadgeSize badge-pill">
              {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(total_general_edit)}
            </Badge>
          </h4>
        </ModalHeader>
        <ModalBody>
        {/* <Form onSubmit={savePago}> */}
          
        <Table striped borderless>
                <thead>
                  <tr>
                  <th>Fecha</th>
                  <th>Nota</th>
                  <th>Total</th>
                  <th>Abonos</th>
                  <th>Saldo</th>
                  <th>Importe</th>
                  <th>Observaciones</th>
                  </tr>
                </thead>
                <tbody>
                  {inputFields.map((a) => (
                      // <div key={a.id}>
                            <tr>
                            <td>{a.fecha}</td>
                            <td>{a.nota}</td>
                            <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.total_general)}</td>
                            <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.total_general-a.saldo)}</td>
                            <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.saldo)}</td>
                            <td>
                              <Input
                              bsSize="sm"
                                type="number"
min="0"
step="any" 
                                name="importe"
                                value={a.importe}
                                key={a.id}
                                onChange ={(e)=>{handleChangeInputImporte(a.id, e)
                                }}/>
                            </td>
                            <td>
                              <Input
                              bsSize="sm"
                                type="text" 
                                name="observaciones"
                                value={a.observaciones}
                                key={a.id}
                                onChange ={(e)=>{handleChangeInputObser(a.id, e)
                                }}/>
                            </td>
                            </tr>
                          // </div>
                  ))}

{inputFieldsNC.map((a) => (
                      // <div key={a.id}>
                            <tr>
                            <td>{a.fecha}</td>
                            <td>{a.nota}</td>
                            <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.total_general)}</td>
                            <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.total_general-a.saldo)}</td>
                            <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.saldo)}</td>
                            <td>
                              <Input
                              bsSize="sm"
                                type="number"
min="0"
step="any" 
                                name="importe"
                                value={a.importe}
                                key={a.id}
                                onChange ={(e)=>{handleChangeInputImporteNC(a.id, e)
                                }}/>
                            </td>
                            <td>
                              <Input
                              bsSize="sm"
                                type="text" 
                                name="observaciones"
                                value={a.observaciones}
                                key={a.id}
                                onChange ={(e)=>{handleChangeInputObserNC(a.id, e)
                                }}/>
                            </td>
                            </tr>
                          // </div>
                  ))}
                      <tr>
                        <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="negrita" align="center">
                        Total
                      </td>
                      <td className="negrita">{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(total)}</td>
                      <td></td>
                    </tr>
                </tbody>
                <br />
              </Table>

            <br />
              {validaBoton ? (
            <Button
            className="btn btn-success"
            onClick={savePago}
          > Registrar
          </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
          {/* </Form>      */}
        </ModalBody>
      </Modal>    
        </div>
      ) : undefined}
      <br />
      {loader}
    </>
  );
}

export default ListadoDevolucionesAdmin;
